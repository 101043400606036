<template>
  <div>
    <CyDetails
      :item-id="orgCanonical"
      :on-save="inviteMembers"
      :save-btn-text="$tc('sendLabel', emails.length, { nb: emails.length })"
      :on-cancel="() => $resetData('emails')"
      :loading="loading"
      :saving="saving"
      :can-save="canSave"
      :can-cancel="!!emails.length"
      hide-delete>
      <template slot="details_form">
        <CyNotification
          theme="error"
          :content="errors"/>

        <div>
          <p>
            {{ emails.length === 0
              ? $t('titleExplanation')
              : $tc('inviteCount', emails.length, { nb: emails.length })
            }}
          </p>

          <div class="add-widget">
            <v-text-field
              v-model="emailInput"
              data-cy="email-input"
              :label="$t('forms.fieldEmails')"
              required
              class="required-field"
              persistent-hint
              :hint="$t('addMembersHint')"
              clearable
              @keyup.enter="addEmails"
              @blur="addEmails"/>

            <div class="email-list d-flex flex-column align-start">
              <CyTag
                v-for="(email, index) of emails"
                :key="index"
                :variant="isInvalidEmail(email) ? 'error' : 'primary'"
                small
                icon-after="close"
                class="email mt-1"
                @click-icon-after="removeEmail(email)">
                {{ email }}
              </CyTag>
            </div>

            <div
              v-if="!_.isEmpty(emailErrors)"
              class="v-text-field__details mt-2">
              <div
                class="v-messages theme--light error--text"
                role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    {{ emailErrors[0] }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p>{{ $t('withRole') }}</p>
          <v-select
            v-model="$v.role.$model"
            :hint="$t('roleHint')"
            :items="available.roles"
            :label="$t('forms.role')"
            item-value="canonical"
            item-text="name"
            required
            class="required-field mt-0"/>
        </div>
      </template>
    </CyDetails>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { constructBreadcrumb } from '@/utils/helpers'
import { required, email } from 'vuelidate/lib/validators'
import CyDetails from '@/components/details'

export default {
  name: 'CyPageMemberInvite',
  components: {
    CyDetails,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('invite'), [
      {
        label: this.$t('routes.members'),
        name: 'members',
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.membersInvite'),
      description: {
        text: this.$t('headerDescription'),
      },
    }
  },
  validations: {
    emails: {
      $each: {
        required,
        email,
      },
    },
    role: { required },
  },
  data: () => ({
    loading: true,
    saving: false,
    emailInput: '',
    emails: [],
    role: '',
  }),
  computed: {
    ...mapState('organization', {
      orgErrors: (state) => state.errors,
      available: (state) => state.available,
    }),
    emailErrors () {
      const errors = []
      const hasInvalidEmails = _.values(this.$v.emails.$each.$iter).some(({ $invalid }) => $invalid)
      if (hasInvalidEmails) errors.push(this.$t('emailRuleNotValid'))
      return errors
    },
    invalidEmails () {
      return _.values(this.$v.emails.$each.$iter)
        .filter(({ $invalid }) => $invalid)
        .map(({ $model }) => $model)
    },
    errors () {
      return [
        ...this.orgErrors.roles,
        ...this.orgErrors.members,
        ...this.orgErrors.invitedMembers,
      ]
    },
    canSave () {
      return !_.isEmpty(this.emails) && !this.$v.$invalid
    },
  },
  async mounted () {
    await this.FETCH_AVAILABLE({ keyPath: 'roles' })
    this.role = this.available.roles.find(({ name }) => name === 'Organization Member')?.canonical
    this.$toggle.loading(false)
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
      'INVITE_ORG_MEMBERS',
    ]),
    ...mapActions('organization/licence', [
      'GET_LICENCE',
    ]),
    isInvalidEmail (email) {
      return this.invalidEmails.includes(email)
    },
    addEmails () {
      if (_.isEmpty(this.emailInput)) return
      // Converts string into array of emails, changes ';' and ' ' to ',' and removes empty entries
      const emailsToAdd = _.filter(this.emailInput.replace(/;/g, ',').replace(/\s/g, ',').split(','))
      const currentEmails = _.cloneDeep(this.emails)

      this.emails = _.uniq([
        ...emailsToAdd, // newest on top
        ...currentEmails,
      ])

      this.emailInput = ''
    },
    removeEmail (email) {
      this.emails.splice(this.emails.indexOf(email), 1)
    },
    async inviteMembers () {
      this.$toggle.saving(true)
      const { $router, role } = this
      const invitedMembers = _.map(this.emails, (email) => ({ email, role_canonical: role }))
      await this.INVITE_ORG_MEMBERS({ invitedMembers, $router })
      this.$toggle.saving(false)
      await this.GET_LICENCE()
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.membersInvite',
        addMembersHint: 'You can provide a single email or a list of emails here, separated by spaces, commas or semi-colons.',
        emailRuleNotValid: 'All emails must be valid',
        inviteCount: 'Invite {nb} contact to the organization | Invite {nb} contacts to the organization',
        headerDescription: 'Invite people to your organization',
        roleHint: 'Override the default organization @:role',
        sendLabel: 'Send 1 invitation | Send {nb} invitations',
        titleExplanation: 'Here you can define a list of contacts to be invited to your organization.',
        withRole: 'With the following @:role:',
      },
      es: {
        title: '@:routes.membersInvite',
        addMembersHint: 'Puede proporcionar un solo correo electrónico o una lista de correos electrónicos aquí, separados por espacios, comas o punto y coma.',
        emailRuleNotValid: 'Los email deben ser válidos',
        inviteCount: 'Invitar {nb} contact a la organización | Invitar {nb} contactos a la organización',
        headerDescription: 'Invitar a personas a su organización',
        roleHint: 'Sobreescribe el @:role por defecto de la organización',
        sendLabel: 'Enviar 1 invitación | Enviar {nb} invitaciones',
        titleExplanation: 'Aquí puede definir una lista de contactos para invitar a su organización.',
        withRole: 'Con el @:role:',
      },
      fr: {
        title: '@:routes.membersInvite',
        addMembersHint: `Vous pouvez fournir ici un seul email ou une liste d'emails, séparés par des espaces, des virgules ou des points-virgules.`,
        emailRuleNotValid: 'Les emails doivent être valides',
        inviteCount: `Inviter {nb} contact à l'organisation | Inviter {nb} contacts à l'organisation`,
        headerDescription: 'Inviter des personnes dans votre organisation',
        roleHint: `Remplace le @:role par defaut de l'organisation`,
        sendLabel: 'Envoyer 1 invitation | Envoyer {nb} invitations',
        titleExplanation: 'Ici vous pouvez définir une liste de contacts à inviter dans votre organization.',
        withRole: 'Avec le @:role:',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .member-invite .v-menu__content {
    display: none !important;
  }

  .member-invite {
    width: 300px;
  }

  .member-invite-added-mail {
    display: inline-block;

    p {
      display: inline-block;
    }
  }

  .add-widget ::v-deep {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 24px;

    .v-text-field > .v-input__control > .v-input__slot {
      &::before {
        position: absolute;
        top: 2em;
      }

      &::after {
        position: absolute;
        top: 2em;
        bottom: auto;
      }
    }

    .v-input__append-inner {
      position: absolute;
      right: 0;
    }

    .v-select__selections {
      @extend %cy-scrollbars-always-show;

      flex-flow: column nowrap;
      align-items: flex-start;
      width: 100%;
      min-height: 0;
      max-height: 400px;
      margin-top: 2.5em;
      padding: 0;

      input[role="combobox"] {
        position: absolute;
        top: 0;
        left: 0;
        flex: 1 0 100%;
        order: -1;
      }

      .v-tooltip {
        width: 100%;
        max-width: 100%;
      }

      .v-tooltip + .v-tooltip {
        margin-top: 8px;
      }

      .tag {
        display: inline-flex;

        > .email {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > .tag__icon {
          cursor: pointer;
        }
      }

      .v-chip {
        width: 290px;

        &__content {
          width: 100%;
        }

        &.success {
          background: get-color("success", $alpha: 0.9) !important;
        }

        .email-text {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .no-emails {
      .v-select__selections {
        min-height: 32px;
        margin: 0;
      }
    }
  }
</style>
